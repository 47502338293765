import React from 'react';
import Layout from '../../components/Shared/Layout';
import Jobbox from '../../components/Portfolio/jobbox';
import '../../assets/css/jobbox.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;

class JobboxPage extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Portfolio/jobbox',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <Jobbox />
      </Layout>
    );
  }
}

export default JobboxPage;
